import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import { LINKS } from '../../LINKS'
import { setModalOpen, setModalFilterId, setModalFilterVersion } from '../../redux/globalSlice'
import { batch, useDispatch } from "react-redux";
import DebugLog from "../DebugLog";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import {  IFilterCurrent } from '../../types/IFilter'


interface iFilter {
  filterId: string
  filterCur: IFilterCurrent,
  version?: boolean
}

const FilterRow = ({ filterId, filterCur, version = false }: iFilter) => {

  const dispatch = useDispatch()
  const toFilter = () => {
    batch(() => {
      const id =  filterId
      dispatch(setModalFilterVersion(version))
      dispatch(setModalFilterId(id))
      dispatch(setModalOpen(true))
    })
  }

  const link = version ? `${LINKS.filtrZakazky}/historie/${filterId}` : `${LINKS.filtrZakazky}/${filterId}`

  return (
    <TableRow hover key={filterId} onClick={toFilter} style={{ cursor: "pointer" }}>
      <TableCell component="th" scope="row" width={'50%'}>
        {filterCur?.name}
        <DebugLog val={filterCur}/>
      </TableCell>
      <TableCell width={'5%'}>{filterCur.notifyActive ? 'Ano' : ' Ne'}</TableCell>
      <TableCell width={'10%'}>{filterCur.createdAt ? moment(filterCur.createdAt).format('DD.MM.YYYY HH:mm:ss') : ''}</TableCell>
      <TableCell width={'10%'}>{filterCur.replacedAt ? moment(filterCur.replacedAt).format('DD.MM.YYYY HH:mm:ss') : 'Aktuální'}</TableCell>
      <TableCell width={'20%'}>
        <Button component={Link} variant="contained" size="small" color="primary"
                onClick={(e: any) => e.stopPropagation()} to={link}>
          Seznam zakázek
        </Button>
      </TableCell>

    </TableRow>
  );
}

export default FilterRow;
