import { useEffect, useState } from 'react'
import PodoApi from '../api/PodoApi'

const USER_NAME_PROMISE: { [userId: string]: Promise<any> } = {}
const USER_NAME_DATA: { [userId: string]: string } = {}
export const useUserName = (userId: string) => {

  const [userName, setUserName] = useState('')

  useEffect(() => {
    if (userId in USER_NAME_DATA) {
      setUserName(USER_NAME_DATA[userId])
    } else {
      if (!(userId in USER_NAME_PROMISE)) {
        USER_NAME_PROMISE[userId] = PodoApi.getUsers(userId)
      }
      USER_NAME_PROMISE[userId].then(data => {
        setUserName(data.username)
      }).catch(e => { })
    }

  }, [userId])

  return userName
}
