import {SnackbarMessage, useSnackbar} from "notistack";

const useToast = () => {

  const { enqueueSnackbar } = useSnackbar();

  const toastOk = (msg: SnackbarMessage) => {
    enqueueSnackbar(msg, { variant: "success" })
  }

  const toastErr = (msg: SnackbarMessage) => {
    enqueueSnackbar(msg, { variant: "error" })
  }
  return { toastOk, toastErr }
}

export default useToast;