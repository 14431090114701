import { Button, TextField } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useState } from 'react'
import PodoApi from '../api/PodoApi'
import AlertErr from '../components/Alert/AlertErr'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import DebugLog from '../components/DebugLog'
import Loading from '../components/Loading/Loading'
import history from '../history'
import useFetch from '../hooks/useFetch'
import { LINKS } from '../LINKS'
import { IUser } from '../types/IUser'

const UsersPage = () => {

  const [userInput, setUserInput] = useState('')

  const fetch = useFetch(
    () => PodoApi.getUsers(userInput)
  )

  const clickSearch = () => {
    fetch.fetch()
  }

  const toDetail = (id: string) => {
    history.push(`${LINKS.uzivatel}/${id}`)
  }

  const loading = fetch.loading
  const err = fetch.err
  const users = fetch.data ? Array.isArray(fetch.data) ? fetch.data : [fetch.data] : null

  return (
    <div>
      <Breadcrumbs items={[
        { label: 'Home', link: '/' },
        { label: 'Uživatelé' },
      ]}/>
      <h1>Vyhledání uživatele</h1>

      <div>
        <TextField id="standard-cddid" label="Email/cddid/id" value={userInput}
                   onChange={e => setUserInput(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={clickSearch}>Vyhledat</Button>
      </div>
      <br/>
      <br/>

      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {(!(loading || err) && users) &&
        <>
          <DebugLog val={users}/>
          <h3>Vyhledáno dle: {userInput}</h3>

          {users.length === 0 ?
            <>Žádný záznam</>
            :
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>CDD ID </TableCell>
                    <TableCell align="center">E-mail</TableCell>
                    <TableCell align="center">Příjmení</TableCell>
                    <TableCell align="center">Jméno</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Issuer</TableCell>
                    <TableCell align="center">Podo ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user: IUser) => (
                    <TableRow key={user.id} style={{ cursor: 'pointer' }} onClick={() => toDetail(user.id)}>
                      <TableCell component="th" scope="row">
                        {user.cddId}
                      </TableCell>
                      <TableCell align="center">{user.email}</TableCell>
                      <TableCell align="center">{user.firstName}</TableCell>
                      <TableCell align="center">{user.lastName}</TableCell>
                      <TableCell align="center">{user.roles?.join(', ')}</TableCell>
                      <TableCell align="center">{user.issuer?.identifier}</TableCell>
                      <TableCell align="center">{user.id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </>
      }
    </div>
  )
}

export default UsersPage
