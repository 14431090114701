import React from 'react';
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FilterRow from "./FilterRow";
import FilterHistory from "./FilterHistory";
import PodoApi from "../../api/PodoApi";
import useFetch from "../../hooks/useFetch";
import Loading from "../Loading/Loading";
import AlertErr from "../Alert/AlertErr";
import { IFilter } from '../../types/IFilter'


interface iFilterList {
  userId: string
}

const FilterList = ({ userId }: iFilterList) => {

  const { loading, err, data, fetch } = useFetch(
    () => PodoApi.getFiltersByUser(userId)
  )
  const filters = data

  const clickLoadFilter = () => {
    fetch()
  }

  return (
    <>
      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {!(loading || err) &&
      <>

        {!filters ?
          <Button color="primary" variant="contained" onClick={clickLoadFilter}>
            Načíst filtry uživatele
          </Button>
          :
          <>
            <h2>Seznam filtrů uživatele včetně jejich historie</h2>
            {filters.length === 0 ?
              <>Žádný záznam</>
              :
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Název filtru </TableCell>
                      <TableCell>Notifikace</TableCell>
                      {/*<TableCell>Platnost do</TableCell>*/}
                      <TableCell>Vytvořeno</TableCell>
                      <TableCell>Platnost</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {/*<TableBody>*/}
                  {filters.map((filter: IFilter) => (
                    <TableBody key={filter.id}>
                      <FilterRow filterId={filter.id} filterCur={filter.currentVersion}/>
                      <TableRow key={'history' + filter.id}>
                        <TableCell component="th" scope="row" colSpan={5}>
                          <FilterHistory id={filter.id} curId={filter.currentVersion.id} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                  {/*</TableBody>*/}
                </Table>
              </TableContainer>
            }
          </>
        }
      </>
      }
    </>
  );
}

export default FilterList;
