import React, { useState } from 'react';
import { Button, Paper } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface iMoreBox {
  height?: number,
  children: React.ReactNode
}

const MoreBox = ({height = 200,  children }: iMoreBox) => {
  const [open, setOpen] = useState(false)
  return (
    <Paper style={{
      padding: "10px 20px",
      height: (open ? "auto" : height),
      overflow: "hidden",
      position: "relative"
    }}>
      {children}
      <div style={{
        textAlign: "center",
        background: "rgba(255,255,255,0.5)",
        position: open ? 'relative' : 'absolute',
        bottom: -10,
        left: 0,
        right: 0
      }}>

        <Button
          startIcon={open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}

          onClick={() => setOpen(!open)} style={{}}>
          {open ? 'Méně' : 'Více'}
        </Button>
      </div>
    </Paper>
  );
}

export default MoreBox;