import { useKeycloak } from '@react-keycloak/web'
import * as React from 'react'
import { useEffect, useState } from 'react'
import type { RouteProps } from 'react-router-dom'
// import { ApmRoute } from '@elastic/apm-rum-react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { LINKS } from '../../LINKS'
import TokenStore from '../../services/TokenStore'
import Loading from '../Loading/Loading'

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}

export function PrivateRoute ({
  component: Component,
  ...rest
}: PrivateRouteParams) {
  const { initialized, keycloak } = useKeycloak()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const storetokens = TokenStore.getTokens()
    if (initialized) {
      if (storetokens && !keycloak.authenticated) {
        keycloak.login()
      } else {
        setLoading(false)
      }
    }
  }, [keycloak, initialized])

  if (loading) {
    return <Loading/>
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LINKS.login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
