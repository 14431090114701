import Keycloak from "keycloak-js";
import { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";

const config: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || ""
};

export const initOptions: KeycloakInitOptions = {
  onLoad: "check-sso",
  pkceMethod: "S256",
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  checkLoginIframe: false,
  enableLogging: true
};

const keycloak = new Keycloak(config);

export default keycloak;
