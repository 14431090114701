import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { LINKS } from '../../LINKS'
import { selectUserData } from "../../redux/userSlice";
import useLogout from "../../hooks/useLogout";


const Header = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = useSelector(selectUserData)

  const clickLogout = useLogout()


  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Link to={LINKS.home} style={{ marginRight: 30, color: "white", textDecoration: "none" }}>
            <img src={"/logo192.png"} height={35} alt="PoDo BO"/>
          </Link>
          {!!user &&
          <>
            <Button
              color="inherit"
              variant="outlined"
              component={Link}
              to={LINKS.uzivatele}
              style={{ marginRight: 15 }}
            >
              Uživatelé
            </Button>

            <Button
              color="inherit"
              variant="outlined"
              component={Link}
              to={LINKS.notifikace}
              style={{ marginRight: 15 }}
            >
              Notifikace
            </Button>

            <Button
              color="inherit"
              variant="outlined"
              component={Link}
              to={LINKS.notifikaceZakazka}
              style={{ marginRight: 15 }}
            >
              Notifikace na zakázku
            </Button>
          </>
          }


          {!!user ?
            <div style={{ marginLeft: "auto" }}>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                startIcon={<AccountCircle/>}
              >
                {user.firstName} {user.lastName}
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={Link} to={LINKS.profil}>Profil</MenuItem>
                <MenuItem onClick={clickLogout}>Odhlásit</MenuItem>
              </Menu>
            </div>
            :
            <Button color="inherit">Přihlášení</Button>
          }

        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header;
