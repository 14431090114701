import { combineReducers } from "redux";

import userReducer from "./userSlice";
import globalReducer from "./globalSlice";

const reducer = combineReducers({
    user: userReducer,
    global: globalReducer
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;