import React, { useEffect, useState } from "react";
import PodoApi from "../../api/PodoApi";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
import Loading from "../Loading/Loading";
import AlertErr from "../Alert/AlertErr";
import history from "../../history";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { selectModal, setModalFilterId, setModalFilterVersion } from "../../redux/globalSlice";
import { useDispatch, useSelector } from "react-redux";

interface iFilterSwitch {
  id: string,
  curId: string
}

const FilterSwitch = ({ id, curId }: iFilterSwitch) => {

  const dispatch = useDispatch()
  const { open: openModal } = useSelector(selectModal)

  const { loading, err, data: historyFilters, fetch } = useFetch(
    () => PodoApi.getFiltersHistory(id)
  )

  const [val, setVal] = useState(curId)

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeSwitch = (selectId: any) => {
    setVal(selectId)
    const link = selectId !== id ? `/filtr/zakazky/historie/${selectId}` : `/filtr/zakazky/${id}`

    if (openModal) {
      const modalId = selectId !== id ? selectId : id
      const version = selectId !== id ? true : false
      dispatch(setModalFilterVersion(version))
      dispatch(setModalFilterId(modalId))
    } else {
      history.push(link)
    }
  }
//TODO jine mapovani - labbel datum ukonceni + selectId nikdy nebude id
  const options: { label: string, value: string }[] = historyFilters ? [
    // { label: 'Aktuální', value: id },
    ...historyFilters.map((filter: any) => ({
      label: moment(filter.createdAt).format('DD.MM.YYYY HH:mm:ss'),
      value: filter.id
    }))
  ] : []

  return (
    <>
      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {(!(loading || err) && historyFilters) &&
      <FormControl style={{ float: "right" }}>

        <InputLabel>Verze</InputLabel>

        <Select
          value={val}
          onChange={(e) => onChangeSwitch(e.target.value)}
        >
          {options.map(({ value, label }) =>
            <MenuItem key={value} value={value}>{label}</MenuItem>
          )}
        </Select>
      </FormControl>
      }
    </>
  )
}

export default FilterSwitch;
