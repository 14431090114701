import MomentUtils from '@date-io/moment'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PodoApi from '../../api/PodoApi'
import useToast from '../../hooks/useToas'
import { setModalChangePlanUser } from '../../redux/globalSlice'
import { changeUser } from '../../redux/userSlice'
import { IUser } from '../../types/IUser'
import Loading from '../Loading/Loading'

interface iChangePlan {
  user: IUser
}

const PLANS = {
  free: 'free',
  profi: 'profi',
  premium: 'premium',
  excellent: 'excellent',
}

const ChangePlan = ({ user }: iChangePlan) => {

  const dispatch = useDispatch()
  const { toastOk, toastErr } = useToast()

  const options = Object.keys(PLANS)
  const [plan, setPlan] = useState(user.activeSubscription.level)
  const [descr, setDescr] = useState('')
  const [dateStart, setDateStart] = useState<Moment | null>(moment())
  const [dateEnd, setDateEnd] = useState<Moment | null>(null)
  const [loading, setLoading] = useState(false)
  const disableDate = plan === PLANS.free

  const closeModal = () => {
    dispatch(setModalChangePlanUser(undefined))
  }

  const savePlan = () => {

    const d = moment(dateStart)
    const dEnd = moment(dateEnd)

    if ((!disableDate && !dEnd.isValid()) || !d.isValid()) {
      toastErr('Zadejte validní datum')
      return
    }

    setLoading(true)
    const dateStartFormat = d.format('YYYY-MM-DD')
    const dateEndFormat = dEnd.isValid() ? dEnd.format('YYYY-MM-DD') + 'T23:59' : undefined

    const { id } = user

    PodoApi.addSubscription(id, plan, dateStartFormat, dateEndFormat, descr || undefined)
      .then(data =>
        PodoApi.changeUserSubscription(id, data.id, plan, dateStartFormat, dateEndFormat).then(data => {
          // eslint-disable-next-line
          toastOk(<span>Předplatné změněno.</span>)
          dispatch(changeUser())
          closeModal()
        })
      )
      .catch(err => {
        console.log('save plan error', err)
        toastErr('Chyba změny předplatného ' + err.toString())
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (plan === PLANS.free) {
      setDateEnd(null)
    }
  }, [plan])

  if (loading) {
    return <Loading/>
  }

  return (
    <>

      <Grid
        container
        justify={'center'}
        direction="column"
        style={{ margin: '0 0 50px 0' }}
      >

        <FormControl>
          <InputLabel>Předplatné</InputLabel>
          <Select
            value={plan}
            onChange={(e) => setPlan(e.target.value as string)}
          >
            {options.map((value) =>
              <MenuItem key={value} value={value}>{value}</MenuItem>
            )}
          </Select>
        </FormControl>
        <br/>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            label="Platnost od"
            format={'DD.MM.YYYY'}
            value={dateStart}
            onChange={val => setDateStart(val)}
          />
          <KeyboardDatePicker
            disabled={disableDate}
            label="Platnost do"
            format={'DD.MM.YYYY'}
            value={dateEnd}
            onChange={val => setDateEnd(val)}
          />
        </MuiPickersUtilsProvider>

        <br/>
        <div>
          <TextareaAutosize placeholder="Poznámka" style={{ width: '100%' }} value={descr}
                            onChange={e => setDescr(e.target.value)}/>
        </div>
      </Grid>

      <Button color="primary" variant="contained" onClick={() => savePlan()}>
        Nastavit
      </Button>

      <Button color="primary" variant="outlined" onClick={closeModal} style={{ float: 'right' }}>
        Zavřít
      </Button>

    </>
  )
}

export default ChangePlan
