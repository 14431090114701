import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment/moment'
import React from 'react'
import { INotification } from '../../types/INofication'
import DebugLog from '../DebugLog'

interface iNotifications {
  notifications: INotification[]
}

const NotificationsTable = ({ notifications }: iNotifications) => {

  return (
    <>
      {notifications.length === 0 ?
        <>Žádný záznam</>
        :
        <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell align="center">Vytvořeno</TableCell>
                <TableCell align="center">Odesláno</TableCell>
                <TableCell align="center">Typ</TableCell>
                <TableCell align="center">Reason</TableCell>
                <TableCell align="center">Instant</TableCell>
                <TableCell align="center">Zakázky</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((notif) => (
                <TableRow key={notif.id}
                  // style={{ cursor: 'pointer' }} onClick={() => toDetail(notif.id)}
                >

                  <TableCell component="th" scope="row">

                    <strong>{notif.email}</strong>
                    <br/>
                    <small>{notif.owner.split('/').pop()}</small>
                    <DebugLog val={notif}/>
                  </TableCell>
                  <TableCell
                    align="center">{notif.createdAt ? moment(notif.createdAt).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>
                  <TableCell
                    align="center">{notif.sentAt ? moment(notif.sentAt).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>

                  <TableCell align="center">{notif.type}</TableCell>
                  <TableCell align="center">{notif.reason}</TableCell>
                  <TableCell align="center">{notif.instant ? 'ano' : 'ne'}</TableCell>


                  <TableCell align="center">
                    <small>
                      {notif.updatedContracts?.length &&
                        <DebugLog val={notif.updatedContracts}/>
                      }
                    </small>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  )
}

export default NotificationsTable
