import MomentUtils from '@date-io/moment'
import { Button, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Pagination from '@material-ui/lab/Pagination'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PodoApi from '../api/PodoApi'
import AlertErr from '../components/Alert/AlertErr'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import Loading from '../components/Loading/Loading'
import NotificationsTable from '../components/Notifications/NotificationsTable'
import useFetch from '../hooks/useFetch'
import useToast from '../hooks/useToas'
import { LINKS } from '../LINKS'
import { INotificationParams } from '../types/INofication'

const DEF_PARAMS = {
  page: 1,
  itemsPerPage: 100
}

const OPTIONS_REASONS = [
  { value: 'common', label: 'Common' },
  { value: 'new_contract', label: 'New Contract' },
  { value: 'updated_contract', label: 'Updated Contract' },
  { value: 'requests_deadline_at_approaching', label: 'Requests Deadline Approaching' },
  { value: 'offers_deadline_at_approaching', label: 'Offers Deadline Approaching' },
  { value: 'deadline_at_approaching', label: 'Deadline Approaching' },
]

const OPTIONS_TYPES = [
  { value: 'email', label: 'Email' },
  { value: 'push', label: 'Push Notification' },
]
const OPTIONS_SORT = [
  { value: 'order[createdAt]:desc', label: 'Dle vytvoření sestupně' },
  { value: 'order[createdAt]:asc', label: 'Dle vytvoření vzestupně' },
  { value: 'order[sentAt]:desc', label: 'Dle odeslání sestupně' },
  { value: 'order[sentAt]:asc', label: 'Dle odeslání vzestupně' },
]

const NotificationPage = () => {
  const { toastErr } = useToast()

  const [showLoadUserId, setShowLoadUserId] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [users, setUsers] = useState<any[]>([])

  const [params, setParams] = useState<INotificationParams>(DEF_PARAMS)
  const updateFilter = (vals: Partial<INotificationParams>) => setParams({ ...params, ...vals })

  const [sorting, setSortign] = useState('')
  const [sortKey, sortDir] = sorting.split(':')

  const sortingParams = sortKey && sortDir ? { [sortKey]: sortDir } : {}
  const fetchParmas = { ...params, ...sortingParams }

  const { loading, err, data, fetch } = useFetch(
    () => PodoApi.notifications(fetchParmas)
  )

  const { data: notifs, totalCount } = data ?? {}
  const pagesCount = Math.ceil(totalCount / params.itemsPerPage)

  useEffect(() => {
    if (data) { // jen pri zmene - ne pri init
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.page])

  const clickSearch = () => {
    fetch()
  }
  const clickClear = () => {
    setParams(DEF_PARAMS)
  }

  const clickSearchUser = () => {
    PodoApi.getUsers(userInput)
      .then(res => {
        const users = Array.isArray(res) ? res : [res]
        setUsers(users)
      })
      .catch(e => {
        toastErr('Chyba hledání')
      })
  }

  return (
    <div>
      <Breadcrumbs items={[
        { label: 'Home', link: LINKS.home },
        { label: 'Notifikace' },
      ]}/>
      <h1>Vyhledání notifikací</h1>

      <div style={{ display: 'flex', gap: 30, maxWidth: 800 }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              value={params.type || ''}
              onChange={(e) => updateFilter({ type: e.target.value as string })}
            >
              <MenuItem value="">Vybrat...</MenuItem>
              {OPTIONS_TYPES.map(({ value, label }) =>
                <MenuItem key={value} value={value}>{label}</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Reason</InputLabel>
            <Select
              value={params.reason || ''}
              onChange={(e) => updateFilter({ reason: e.target.value as string })}
            >
              <MenuItem value="">Vybrat...</MenuItem>
              {OPTIONS_REASONS.map(({ value, label }) =>
                <MenuItem key={value} value={value}>{label}</MenuItem>
              )}
            </Select>
          </FormControl>

          {/*<TextField id="standard-contract" label="Zakázka ID" value={params.contractId || ''}*/}
          {/*           onChange={e => updateFilter({ contractId: e.target.value })}/>*/}


          <TextField id="standard-owner" label="User ID" value={params.owner || ''}
                     onChange={e => updateFilter({ owner: e.target.value })}/>
          <br/>
          {users.length ?
            <div>
              {users.map(user =>
                <div>
                  {user.id} - {user.email} - {user.cddId}
                  <Button onClick={() => {
                    updateFilter({ owner: user.id })
                    setShowLoadUserId(false)
                    setUserInput('')
                    setUsers([])
                  }}>Vybrat</Button>
                </div>
              )}
            </div>
            :
            <div>
              {showLoadUserId ?
                <TextField id="standard-user" label="Email/cddid" value={userInput}
                           InputProps={{
                             endAdornment: <Button disabled={!userInput} onClick={clickSearchUser}>Vyhledat</Button>
                           }}
                           onChange={e => setUserInput(e.target.value)}/>
                :
                <Button style={{ marginTop: -30 }} size="small" onClick={() => setShowLoadUserId(true)}>
                  načíst id uzivatele
                </Button>
              }
            </div>
          }

        </div>

        <div style={{ minWidth: 400 }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div style={{ display: 'flex', gap: 20 }}>
              <KeyboardDatePicker
                label="Vytvořeno od"
                format={'DD.MM.YYYY HH:mm'}
                value={params['createdAt[after]'] || null}
                onChange={val => updateFilter({ 'createdAt[after]': val ? moment(val).format() : undefined })}
              />
              <KeyboardDatePicker
                label="Vytvořeno do"
                format={'DD.MM.YYYY HH:mm'}
                value={params['createdAt[before]'] || null}
                onChange={val => updateFilter({ 'createdAt[before]': val ? moment(val).format() : undefined })}
              />
            </div>
            <div style={{ display: 'flex', gap: 20 }}>
              <KeyboardDatePicker
                label="Odesláno od"
                format={'DD.MM.YYYY HH:mm'}
                value={params['sentAt[after]'] || null}
                onChange={val => updateFilter({ 'sentAt[after]': val ? moment(val).format() : undefined })}
              />
              <KeyboardDatePicker
                label="Odesláno do"
                format={'DD.MM.YYYY HH:mm'}
                value={params['sentAt[before]'] || null}
                onChange={val => updateFilter({ 'sentAt[before]': val ? moment(val).format() : undefined })}
              />
            </div>
          </MuiPickersUtilsProvider>


          <FormControl>
            <InputLabel>Pořadí</InputLabel>
            <Select
              style={{ width: 200 }}
              value={sorting || ''}
              onChange={(e) => setSortign(e.target.value as string)}
            >
              <MenuItem value="">Vybrat...</MenuItem>
              {OPTIONS_SORT.map(({ value, label }) =>
                <MenuItem key={value} value={value}>{label}</MenuItem>
              )}
            </Select>
          </FormControl>


        </div>
      </div>
      <br/>
      <br/>
      <Button variant="contained" color="primary" onClick={clickSearch}>Vyhledat</Button>
      <Button variant="contained" color="secondary" onClick={clickClear}>Smazat filtr</Button>

      <br/>
      <br/>

      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {
        (!(loading || err) && notifs) &&
        <>
          <NotificationsTable notifications={notifs}/>

          <Pagination
            style={{ margin: '10px' }}
            count={pagesCount}
            page={params.page}
            onChange={(e, val) => updateFilter({ page: val })}/>
        </>
      }

    </div>
  )
}

export default NotificationPage
