import React from 'react';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {setModalChangePlanUser} from '../../redux/globalSlice'

interface iChangePlan {
  user: any
}

const ChangePlanBtn = ({user}: iChangePlan) => {

  const dispatch = useDispatch()

  const handleClickChangePlan = () => {
    dispatch(setModalChangePlanUser(user))
  }

  if (!user || !user.cddId) {
    return null
  }

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClickChangePlan}>
        Změnit předplatné
      </Button>
    </>
  );
}

export default ChangePlanBtn;