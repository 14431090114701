import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

interface iDebugLog {
  val: any
}

const DebugLog = ({ val }: iDebugLog) => {

  const [open, setOpen] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(!open)
  }
  return (
    <div>
      <small style={{ cursor: 'pointer' }} onClick={handleClick}>
        {open ? 'Méně' : <MoreHorizIcon color="disabled" fontSize="small"/>}
      </small>
      {open &&
      <pre>
        {JSON.stringify(val, null, 2)}
      </pre>
      }
    </div>
  );
}

export default DebugLog;