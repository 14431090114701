import React from "react";
import { Link } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import BreadcrumbsMUI from '@material-ui/core/Breadcrumbs';

// import Link from '@material-ui/core/Link';

interface iBreadc {
  label: string,
  link?: string
}

interface iBreadcrumb {
  items: iBreadc[]
}

const Breadcrumbs = ({ items }: iBreadcrumb) => {

  if (items.length === 0) {
    return null;
  }

  return (
    <BreadcrumbsMUI aria-label="breadcrumb" style={{marginTop:10}}>
      {items.map((item, index) =>
        item.link ?
          <Link key={index} to={item.link}>
            {item.label}
          </Link>
          :
          <Typography key={index} color="textPrimary">{item.label}</Typography>
      )}

    </BreadcrumbsMUI>
  );
};


export default Breadcrumbs;