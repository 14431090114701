import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import FilterDetail from "../Filter/FilterDetail";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from '@material-ui/core/DialogContent';

import { selectModal, setModalOpen } from '../../redux/globalSlice'
// import { withTransaction } from '@elastic/apm-rum-react'

const ModalFilterDetail = () => {
  const dispatch = useDispatch()
  const { open, filterId, version } = useSelector(selectModal)

  const handleClose = () => {
    dispatch(setModalOpen(false))
  };

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={"xl"}>
        <DialogContent>
          {open && <FilterDetail id={filterId} version={version}/>}
        </DialogContent>
      </Dialog>
    </>
  );
}

// export default withTransaction('ModalDetail', 'component')(ModalFilterDetail );
export default ModalFilterDetail;