import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useKeycloak } from "@react-keycloak/web";
import { Redirect, useLocation } from 'react-router-dom'
import { Grid } from "@material-ui/core";

const LoginPage = () => {

  const location = useLocation<{ [key: string]: unknown }>()
  const currentLocationState = location.state || {
    from: { pathname: '/' }
  }
  //console.log('CURRENT LOCATION STATE ', currentLocationState)

  const { keycloak } = useKeycloak()

  const clickLogin = useCallback(() => {
    keycloak?.login()
  }, [keycloak])

  if (keycloak?.authenticated)
    return <Redirect to={currentLocationState?.from as string}/>

  return (
    <>
      <h1 style={{ textAlign: "center" }}>
        Portál dodavatele - BACKOFFICE
      </h1>

      <Grid
        style={{ marginTop: 150 }}
        container
        justify="center">
        <Button variant="contained" color="primary" onClick={clickLogin}>
          Přihlásit
        </Button>

      </Grid>
    </>
  )
}

export default LoginPage;