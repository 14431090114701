import React from 'react';
import Button from '@material-ui/core/Button';
import {Grid} from "@material-ui/core";
import useLogout from "../hooks/useLogout";
import AlertErr from "../components/Alert/AlertErr";

const LogoutPage = () => {

  const clickLogout = useLogout()

  return (
    <>
      <h1 style={{textAlign: "center"}}>
        Portál dodavatele - BACKOFFICE
      </h1>

      <Grid
        style={{marginTop: 150}}
        container
        direction="column"
        alignItems="center"
        justify="center">
        <AlertErr>
          Uživatel nemá oprávnění
        </AlertErr>
        <br/>
        <br/>
        <Button  variant="contained" color="primary" onClick={clickLogout}>
          Odhlásit
        </Button>

      </Grid>
    </>
  )
}

export default LogoutPage;
