import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PodoApi from '../api/PodoApi'
import AlertErr from '../components/Alert/AlertErr'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import ChangePlanBtn from '../components/ChangePlan/ChangePlanBtn'
import DebugLog from '../components/DebugLog'
import FilterList from '../components/Filter/FilterList'
import Loading from '../components/Loading/Loading'
import SubscriptionsList from '../components/Subscriptions/SubscriptionsList'
import { LINKS } from '../LINKS'
import { selectChangeUserIndex } from '../redux/userSlice'
import useFetch from '../hooks/useFetch'
import { IUser } from '../types/IUser'

const UserDetailPage = () => {

  let { id } = useParams<{ id: string }>()

  const changeUSerIndex = useSelector(selectChangeUserIndex)

  const { loading, err, data, fetch } = useFetch(
    () => PodoApi.getUsers(id)
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, changeUSerIndex])

  const addRow = (label: string, value: string) => ({ label, value })

  const user: IUser = data ? data : {}

  const rows = [
    addRow('CDD ID', user.cddId),
    addRow('Podo ID', user.id),
    addRow('Uživatelské jméno', user.username),
    addRow('Předplatné', user.activeSubscription?.level),
    addRow(' - Stav', user.activeSubscription?.status),
    addRow(' - Platnost od', user.activeSubscription?.validFrom ? moment(user.activeSubscription.validFrom).format('DD.MM.YYYY HH:mm') : '-'),
    addRow(' - Platnost do', user.activeSubscription?.validTo ? moment(user.activeSubscription.validTo).format('DD.MM.YYYY HH:mm') : '-'),

    addRow('Křestní jméno', user.firstName),
    addRow('Příjmení', user.lastName),
    addRow('E-mail', user.email),
    addRow('Telefonní číslo', user.phone),
    addRow('Web', user.website),
    addRow('Okamžité oznamování', user.instantNotifications ? 'Ano' : 'Ne'),
    addRow('Email pro zasílani notifikací', user.emailNotification || '' ),
    addRow('V notifikačním e-mailu uvádět navíc vybrané nejnovější zakázky', user.notificationIncludingNewestActive ? 'Ano' : 'Ne' ),
    addRow('Interval', user.notificationSendEmptyInterval || '' ),
    addRow('Název firmy', user.company),
    addRow('IČO', user.cin),
    addRow('DIČ', user.vatin),
    addRow('Fakturační adresa', ''),
    addRow(' - Ulice', user.street),
    addRow(' - Číslo popisné', user.streetNumber),
    addRow(' - Město', user.city),
    addRow(' - PSČ', user.zipCode),
    addRow(' - Země', user.country),
  ]

  return (
    <>
      <Breadcrumbs items={[
        { label: 'Home', link: LINKS.home },
        { label: 'Uživatelé', link: LINKS.uzivatele },
        { label: 'Detail uživatele' }
      ]}/>
      <h1>Profil uživatele</h1>

      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {!(loading || err) &&
        <>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DebugLog val={user}/>

          <br/>
          <br/>
          <ChangePlanBtn user={user}/>
          <br/>
          <br/>
          <SubscriptionsList userId={id}/>
          <br/>
          <br/>
          <FilterList userId={id}/>
          <br/>
          <br/>
          {/*TODO nepouzito - BE API nedokaze vypsat*/}
          {/*<NotificationsUserList userId={id}/>*/}
          <br/>
          <br/>
          <br/>
        </>
      }

    </>
  )
}

export default UserDetailPage
