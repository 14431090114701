import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {

  return (
    <div style={{textAlign:'center', margin: '25px 0'}}>
      <CircularProgress />
    </div>
  )
}

export default Loading;