import { useState } from "react";

// import apm from "../rum";


const useFetch = (fetchFce: () => Promise<any>) => {

  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')
  const [data, setData] = useState(null as any)


  const fetch = () => {
    //console.log('FETCH')
    setLoading(true)
    setData(null)
    setErr('')
    fetchFce()
      .then(res => {
        console.log('fetch response: ', res)
        setData(res)
      })
      .catch(err => {
        // apm.captureError('err fetch ' + err.toString())
        console.error('err fetch: ', err, err?.response)
        const errmsg = err.response?.data?.error?.description || err.toString()
        setErr(errmsg)
      })
      .finally(() => setLoading(false))
  }

  return { loading, err, data, fetch }
}

export default useFetch;
