import { LINKS } from '../LINKS'
import SnackbarOutside from "./snackOutside";
import TokenStore from "../services/TokenStore";
import keycloak from "./keycloak";

const logout = () =>{
  SnackbarOutside.error('Relace vyprsela')
  TokenStore.clearTokens()
  keycloak?.logout({ redirectUri: `${window.location.origin}${LINKS.login}` })
}

export default logout
