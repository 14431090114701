import React from 'react';
import Alert from '@material-ui/lab/Alert';

interface iAlertErr {
  children: React.ReactNode
}

const AlertErr = ({ children }: iAlertErr) => {

  return (
    <Alert severity="error">{children}</Alert>
  );
}

export default AlertErr;