import Container from '@material-ui/core/Container'
import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect } from 'react'
import './App.css'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import PodoApi from '../../api/PodoApi'
import history from '../../history'
import useToast from '../../hooks/useToas'
import { LINKS } from '../../LINKS'
import FilterContratcsPage from '../../pages/FilterContratcsPage'
import HomePage from '../../pages/HomePage'
import LoginPage from '../../pages/LoginPage'
import LogoutPage from '../../pages/LogoutPage'
import NotificationContractPage from '../../pages/NotificationContractPage'
import NotificationPage from '../../pages/NotificationPage'
import ProfilPage from '../../pages/ProfilPage'
import UserDetailPage from '../../pages/UserDetailPage'
import UsersPage from '../../pages/UsersPage'
import { setUserData } from '../../redux/userSlice'
import TokenStore from '../../services/TokenStore'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Loading from '../Loading/Loading'
import ModalChangePlan from '../ModalDialog/ModalChangePlan'
import ModalFilterDetail from '../ModalDialog/ModalFilterDetail'
import { PrivateRoute } from './PrivateRoute'

const App = () => {

  const dispatch = useDispatch()
  const { initialized, keycloak } = useKeycloak()

  const { toastOk, toastErr } = useToast()

  useEffect(() => {
    // console.log('KKchange', keycloak.authenticated, keycloak)

    if (keycloak && keycloak.authenticated) {
      //console.log('GET USER info')
      const token = keycloak.token || ''
      const refreshToken = keycloak.refreshToken || ''
      TokenStore.saveTokens(token, refreshToken)
      PodoApi.setToken(token)
      PodoApi.getLogedUserDetail()
        .then(res => {
          console.log('get cdd info', res)

          const roles = res.roles
          if (!roles.includes('ROLE_ADMIN') && !roles.includes('ROLE_SUPPORT')) {
            toastErr('Uživatel nemá požadované oprávnění')
            // console.log(roles)
            history.push(LINKS.logout)
            // keycloak.logout()
            return
          }

          toastOk('Login')
          dispatch(setUserData(res))
        })
        .catch(err => {
          toastErr('Chyba získání identity: ' + err.response?.data?.error?.description)
          console.error('erro ged cdd information', err)
          history.push(LINKS.logout)
          // keycloak.logout()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, keycloak.authenticated])

  if (!initialized) {
    return <Loading/>
  }

  return (
    <div className="App">

      <Header/>
      <Container maxWidth="lg">

        <Switch>
          <Route path={[LINKS.login]} exact component={LoginPage}/>
          <Route path={[LINKS.logout]} exact component={LogoutPage}/>
          <PrivateRoute path={[LINKS.home, '']} exact component={HomePage}/>
          <PrivateRoute path={[LINKS.uzivatele]} exact component={UsersPage}/>
          <PrivateRoute path={[`${LINKS.uzivatel}/:id`]} exact component={UserDetailPage}/>
          <PrivateRoute path={[`${LINKS.filtrZakazky}/:id`, `${LINKS.filtrZakazky}/:type(historie)/:id`]} exact
                        component={FilterContratcsPage}/>
          <PrivateRoute path={[LINKS.profil]} exact component={ProfilPage}/>
          <PrivateRoute path={[LINKS.notifikaceZakazka]} exact component={NotificationContractPage}/>
          <PrivateRoute path={[LINKS.notifikace]} exact component={NotificationPage}/>

          <Route path="*" component={() => <div>404 Not found.</div>}/>

        </Switch>
        <ModalFilterDetail/>
        <ModalChangePlan/>

      </Container>
      <Footer/>

    </div>
  )
}

export default App
