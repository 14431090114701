import { Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import { LINKS } from '../LINKS'

const HomePage = () => {

  return (
    <>
      <Breadcrumbs items={[
        { label: 'Home' },
      ]}/>
      <h1>PoDo - Back Office</h1>

      <Button component={Link} variant="contained" color="primary" to={LINKS.uzivatele} size="medium">
        Hledat uživatele
      </Button>
      <br/>
      <br/>
      <Button component={Link} variant="contained" color="primary" to={LINKS.notifikace} size="medium">
        Hledat notifikace
      </Button>
      <br/>
      <br/>
      <Button component={Link} variant="contained" color="primary" to={LINKS.notifikaceZakazka} size="medium">
        Hledat notifikace na zakázku
      </Button>
    </>
  )
}

export default HomePage
