import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import FilterDetail from "../components/Filter/FilterDetail";
import { Button, Grid } from "@material-ui/core";
import DebugLog from "../components/DebugLog";
import MoreBox from "../components/MoreBox/MoreBox";
import PodoApi from "../api/PodoApi";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment, { Moment } from "moment";
import Pagination from '@material-ui/lab/Pagination';
import useFetch from "../hooks/useFetch";
import Loading from "../components/Loading/Loading";
import AlertErr from "../components/Alert/AlertErr";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";

import "moment/locale/cs";
import MomentUtils from '@date-io/moment';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { LINKS } from '../LINKS'
import { IContract } from '../types/IContract'
import { IFilter, IFilterCurrent } from '../types/IFilter'

moment.locale("cs"); // it is required to select default locale manually


const URL_FE_DETAIL = `${process.env.REACT_APP_PODO_FE_URL}/detail/`

const FilterContratcsPage = () => {
  let { id, type } = useParams<{ id: string, type: string }>();

  const version = !!type

  const [date, setDate] = useState<Moment | null>(null);
  const [page, setPage] = useState(1)

  const d = moment(date)
  let dateTo = d.isValid() ?
    d.format('YYYY-MM-DDTHH:mm:ssZ') //W3C string
    : undefined

  //console.log('-> convert', date, dateTo)

  const { loading, err, data, fetch } = useFetch(
    async () => {
      const filterCur: IFilterCurrent = await (version ?
        PodoApi.getFilterVersionDetail(id) : PodoApi.getFilterDetail(id).then((filter: IFilter) => filter.currentVersion)
      )
      return PodoApi.getCotractsFilter({ ...filterCur.params, createdAtTo: dateTo }, page)
    }
  )

  useEffect(() => {
    if (data) { // jen pri zmene - ne pri init
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])


  const clickSearch = () => {
    fetch()
  }

  const res:IContract[] = data ? data : []

  const pagesCount = Math.ceil(res.length / 9)

  return (
    <>
      <Breadcrumbs items={[
        { label: "Home", link: LINKS.home },
        { label: "Uživatelé", link: LINKS.uzivatele },
        // { label: "Detail uživatele", link: "/uzivatel/TODO" },
        { label: version ? "Historie filtru" : "Aktuální filtr" }
      ]}/>
      <h1>Seznam zakázek pro filtr:</h1>
      <MoreBox>
        <FilterDetail id={id} version={version}/>
      </MoreBox>

      <Grid
        container
        justify={"center"}
        style={{ margin: '50px 0' }}
      >


        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDateTimePicker
            label="Zobrazit stav ke dni"
            ampm={false}
            format={'DD.MM.YYYY HH:mm'}
            value={date}
            onChange={val => setDate(val)}
            disableFuture
          />
        </MuiPickersUtilsProvider>

        <Button variant="contained" color="primary" onClick={clickSearch}>Zobrazit</Button>

      </Grid>


      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {(!(loading || err) && data) &&
      <>
        {res.length === 0 ?
          <>Žádný záznam</>
          :
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Název zakázky</TableCell>
                    <TableCell>Zadavatel</TableCell>
                    <TableCell>Lhůta</TableCell>
                    <TableCell>Datum zahájení</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {res && res.map((contract: IContract) => (
                    <TableRow key={contract.id}>
                      <TableCell component="th" scope="row">
                        <a href={URL_FE_DETAIL + contract.slug} target="_blank" rel="noreferrer"
                           style={{ color: "inherit", textDecoration: "none" }}>
                          {contract.name}
                        </a>
                        <DebugLog val={contract}/>
                      </TableCell>
                      <TableCell>{contract.submitter.name}</TableCell>
                      <TableCell>{moment(contract.requestsDeadlineAt ? contract.requestsDeadlineAt : contract.offersDeadlineAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                      <TableCell>{contract.beginsAt ? moment(contract.beginsAt).format('DD.MM.YYYY') : '-'}</TableCell>
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>


            <Grid
              style={{ margin: '10px 0' }}
              container
              justify="center"
            >
              <Pagination
                count={pagesCount}
                page={page}
                onChange={(e, val) => setPage(val)}/>
            </Grid>
          </>
        }
      </>
      }
    </>
  );

}

export default FilterContratcsPage;
