import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../types/IUser'
import { RootState } from './reducer'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null as IUser | null,
    changeUserIndex: 0
  },
  reducers: {
    setUserData (state, action: PayloadAction<any>) {
      state.userData = action.payload
    },
    removeUser (state) {
      state.userData = null
    },
    changeUser (state) {
      state.changeUserIndex++
    }
  }
})

export const selectUserData = (state: RootState) => state.user.userData
export const selectChangeUserIndex = (state: RootState) => state.user.changeUserIndex

export const { setUserData, removeUser, changeUser } = userSlice.actions
export default userSlice.reducer
