import React from 'react';


const V = process.env.REACT_APP_VERSION
const Footer = () => {

  return (
    <div style={{ position: "absolute", bottom: 0, right: 0 }}>
      v {V}
    </div>
  )
}

export default Footer;