import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import history from "./history";
import { SnackbarProvider } from 'notistack';

import keycloak, { initOptions } from "./utils/keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import PodoApi from "./api/PodoApi";
import TokenStore from "./services/TokenStore";
import { SnackbarOutsideViewer } from "./utils/snackOutside";
import logout from "./utils/logout";


if (process.env.NODE_ENV !== "development") {
  console.log = () => {
  };
}

const handleKeycloakReadyEvent = (event: unknown, error: unknown) => {
  // console.log('onKeycloakEvent', event, error)
  // if (event === "onReady") store.dispatch(setKeycloakLoading(false));
};

const tokenLogger = (tokens: any) => {
  // console.log('onKeycloakTokens', tokens)
  // PodoApi.setToken(tokens.token)
  // TokenStore.saveTokens(tokens.token, tokens.refreshToken)
  //TODO zde token? - asi neni potreba
}

//
keycloak.onTokenExpired = () => {
  console.log('EXPIRED token expired', keycloak.token);
  keycloak.updateToken(30*60)
    .then(() => {
      PodoApi.setToken(keycloak.token || '')
      TokenStore.saveTokens(keycloak.token || '', keycloak.refreshToken || '')
    console.log('!!!!TOKEN successfully get a new token', keycloak.token)
  }).catch(() => {
    console.error('!!!!TOKEN Chyba refresh tokenu')
    logout()
  });
}


ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      onTokens={tokenLogger}
      authClient={keycloak}
      initOptions={initOptions}
      onEvent={handleKeycloakReadyEvent}>
      <Provider store={store}>
        <Router history={history}>
          <SnackbarProvider>
            <SnackbarOutsideViewer/>
            <App/>
          </SnackbarProvider>
        </Router>
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
