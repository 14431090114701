import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./reducer";

const slice = createSlice({
  name: "global",
  initialState: {
    modalOpen: false,
    modalFilterId: '',
    modalFilterVersion: false,
    modalChangePlanUser: undefined as any
  },
  reducers: {
    setModalOpen(state, action: PayloadAction<boolean>) {
      state.modalOpen = action.payload
    },
    setModalFilterId(state, action: PayloadAction<string>) {
      state.modalFilterId = action.payload
    },
    setModalFilterVersion(state, action: PayloadAction<boolean>) {
      state.modalFilterVersion = action.payload
    },
    setModalChangePlanUser(state, action: PayloadAction<any>) {
      state.modalChangePlanUser = action.payload
    }
  }
});

export const selectModalChangePlan = (state: RootState) => ({
  open: !!state.global.modalChangePlanUser,
  userData: state.global.modalChangePlanUser
})

export const selectModal = (state: RootState) => ({
  open: state.global.modalOpen,
  filterId: state.global.modalFilterId,
  version: state.global.modalFilterVersion,
})

export const {setModalOpen, setModalFilterId, setModalFilterVersion, setModalChangePlanUser} = slice.actions;
export default slice.reducer;
