import React from 'react'
import { useUserName } from '../../hooks/useUserName'

interface iUserName {
  userId: string
}

const UserName = ({ userId }: iUserName) => {

  const userName = useUserName(userId)
  return (
    <>
      {userName || userId}
    </>
  )
}

export default UserName
