
const ID = "podo_bo_token";

//  interface iTokens {
//   token: string,
//   refreshToken: string,
// }

class TokenStore {

  saveTokens = (token: string, refreshToken: string) => {
    const data = { token, refreshToken }
    localStorage.setItem(ID, JSON.stringify(data));
  }

  getTokens = () => {
    const tokens = localStorage.getItem(ID);
    return tokens ? JSON.parse(tokens) : null;
  }

  clearTokens = () => {
    localStorage.removeItem(ID);
  }
}

const TS = new TokenStore()
export default TS
