import React from 'react';
import Button from "@material-ui/core/Button";
import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/userSlice";
import DebugLog from "../components/DebugLog";
import Loading from "../components/Loading/Loading";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import useLogout from "../hooks/useLogout";

const ProfilPage = () => {

  const user = useSelector(selectUserData)
  const { initialized, keycloak } = useKeycloak()

  const clickLogout = useLogout()

  if (!user) {
    return <Loading/>
  }

  const addRow = (label: string, value: string) => ({ label, value })

  const rows = [
    addRow('ID', user.id),
    addRow('Login', user.username),
    addRow('Jméno', user.firstName),
    addRow('Příjmení', user.lastName),
    addRow('Role', user.roles?.join(', ') || ''),

  ]
  return (
    <>
      <Breadcrumbs items={[
        { label: "Home", link: "/" },
        { label: "Profil" }
      ]}/>
      <h1>Profil</h1>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DebugLog val={user}/>

      {initialized ?
        <>
          Keycloak info <br/>
          <DebugLog val={keycloak}/>
        </>
        : <h2>keycloak initializing ....!!!!</h2>
      }

      <Button variant="contained" color="primary" onClick={clickLogout}>
        Odhlásit
      </Button>
    </>
  );
}

export default ProfilPage;
