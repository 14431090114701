import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment/moment'
import React from 'react'
// import SubscriptionsRow from './SubscriptionsRow'
// import SubscriptionsHistory from './SubscriptionsHistory'
import PodoApi from '../../api/PodoApi'
import useFetch from '../../hooks/useFetch'
import { ISubscription } from '../../types/ISubscriptions'
import AlertErr from '../Alert/AlertErr'
import DebugLog from '../DebugLog'
import Loading from '../Loading/Loading'
import UserName from './UserName'

interface iSubscriptionsList {
  userId: string
}

const SubscriptionsList = ({ userId }: iSubscriptionsList) => {

  const { loading, err, data, fetch } = useFetch(
    () => PodoApi.getSubscriptionsByUser(userId)
  )
  const subscrs = data

  const clickLoadSubscriptions = () => {
    fetch()
  }

  return (
    <>
      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {!(loading || err) &&
        <>

          {!subscrs ?
            <Button color="primary" variant="contained" onClick={clickLoadSubscriptions}>
              Načíst předplatné uživatele
            </Button>
            :
            <>
              <h2>Historie předplatných uživatele</h2>
              {subscrs.length === 0 ?
                <>Žádný záznam</>
                :
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID předplatného</TableCell>
                        <TableCell>Stav</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell>Platnost od</TableCell>
                        <TableCell>Platnost do</TableCell>
                        <TableCell>Vytvořeno</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {/*<TableBody>*/}
                    {subscrs.map((subs: ISubscription) => (
                      <TableBody key={subs.id}>
                        <TableRow hover key={subs.id}>
                          <TableCell component="th" scope="row" width={'30%'}>
                            {subs?.id}
                            <DebugLog val={subs}/>
                          </TableCell>
                          <TableCell width={'10%'}>{subs.status}</TableCell>
                          <TableCell width={'10%'}>{subs.level}</TableCell>
                          <TableCell width={'10%'}>
                            {subs.validFrom ? moment(subs.validFrom).format('DD.MM.YYYY HH:mm:ss') : ''}
                          </TableCell>
                          <TableCell width={'10%'}>
                            {subs.validTo ? moment(subs.validTo).format('DD.MM.YYYY HH:mm:ss') : ''}
                          </TableCell>
                          <TableCell width={'15%'}>
                            {subs.createdAt ? moment(subs.createdAt).format('DD.MM.YYYY HH:mm:ss') : ''}
                            <br/>
                            (<UserName userId={subs.createdBy?.split('/').slice(-1)[0]}/>)
                          </TableCell>
                          <TableCell width={'10%'}>{subs.createdManually ? 'Manuálně' : 'Automaticky'}</TableCell>
                          <TableCell width={'10%'} title={subs.description}>
                            {subs.description?.slice(0, 50)} {subs.description?.length > 50 && '...'}
                          </TableCell>

                        </TableRow>
                      </TableBody>
                    ))}
                    {/*</TableBody>*/}
                  </Table>
                </TableContainer>
              }
            </>
          }
        </>
      }
    </>
  )
}

export default SubscriptionsList
