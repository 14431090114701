import { useKeycloak } from '@react-keycloak/web'
import { useCallback } from 'react'
import { LINKS } from '../LINKS'
import TokenStore from '../services/TokenStore'

const useLogout = () => {

  const { keycloak } = useKeycloak()
  const logout = useCallback(() => {
    TokenStore.clearTokens()
    keycloak?.logout({ redirectUri: `${window.location.origin}${LINKS.login}` })
  }, [keycloak])
  return logout
}

export default useLogout
