import React, { useEffect } from 'react';
import PodoApi from "../../api/PodoApi";
import FilterRow from "./FilterRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Loading from "../Loading/Loading";
import AlertErr from "../Alert/AlertErr";
import useFetch from "../../hooks/useFetch";
import {  IFilterCurrent } from '../../types/IFilter'

interface iFilterHistory {
  id: string
  curId: string
}

const FilterHistory = ({ id, curId }: iFilterHistory) => {

  const { loading, err, data, fetch } = useFetch(
    () => PodoApi.getFiltersHistory(id)
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filters = data ? data as IFilterCurrent[] : []
  return (
    <>
      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {!(loading || err) &&
      <Table size="small">
        <TableBody>
          {filters.filter(filterCur => filterCur.id !== curId).map(filterCur => (
            <FilterRow key={filterCur.id} version={true} filterId={filterCur.id} filterCur={filterCur}/>
          ))}
        </TableBody>
      </Table>
      }
    </>
  );
}

export default FilterHistory;
