import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from "react-redux";
import DialogContent from '@material-ui/core/DialogContent';
import {selectModalChangePlan, setModalChangePlanUser} from '../../redux/globalSlice'
import {DialogTitle} from "@material-ui/core";
import ChangePlan from "../ChangePlan/ChangePlan";

const ModalChangePlan = () => {
  const dispatch = useDispatch()
  const {open, userData} = useSelector(selectModalChangePlan)

  const handleClose = () => {
    dispatch(setModalChangePlanUser(undefined))
  };

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={"xl"}>
        <DialogTitle id="alert-dialog-title">
          {"Nastavení předplatného"}
        </DialogTitle>
        <DialogContent>
          {open && <ChangePlan user={userData}/>}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ModalChangePlan;